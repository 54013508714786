<template>
    <section class="signup-section" id="signup">
            <div class="container px-4 px-lg-5">
                <div class="row gx-4 gx-lg-5">
                    <div class="col-md-10 col-lg-8 mx-auto text-center">
                        <i class="far fa-paper-plane fa-2x mb-2 text-white"></i>
                        <h2 class="text-white mb-5">Skicka ett meddelande</h2>

                        <form class="form-signup" id="contactForm" @submit.prevent="contact">
                            <!-- Name input -->
                            <div class="mb-3">
                                <label for="name" class="form-label" style="color: white; font-size: 1.25rem;">Namn</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    id="name" 
                                    placeholder="Namn" 
                                    aria-label="Namn"
                                    v-model="contactForm.name" 
                                    required
                                     />
                            </div>

                            <!-- Email address input -->
                            <div class="mb-3">
                                <label for="emailAddress" class="form-label" style="color: white; font-size: 1.25rem;">Email</label>
                                <input 
                                    type="email" 
                                    class="form-control" 
                                    id="emailAddress" 
                                    placeholder="Email" 
                                    aria-label="Email"
                                    v-model="contactForm.email"  
                                    required
                                     />
                            </div>

                            <!-- Registration Number (REG) input -->
                            <div class="mb-3">
                                <label for="regNumber" class="form-label" style="color: white; font-size: 1.25rem;">Registreringsnummer</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    id="regNumber" 
                                    placeholder="Registreringsnummer" 
                                    aria-label="Registreringsnummer"
                                    v-model="contactForm.reg"  
                                    required
                                     />
                            </div>

                            <!-- Message input -->
                            <div class="mb-3">
                                <label for="message" class="form-label" style="color: white; font-size: 1.25rem;">Meddelande</label>
                                <textarea 
                                    class="form-control"
                                    id="message" 
                                    rows="5" 
                                    placeholder="Meddelande" 
                                    aria-label="Meddelande"
                                    v-model="contactForm.message"
                                    required
                                    >
                                </textarea>
                            </div>

                            <div class="mb-3">
                                <label for="files" class="form-label" style="color:white; font-size: 1.25rem;">Ladda upp bilder</label>
                                <input
                                type="file"
                                class="form-control"
                                id="files"
                                multiple
                                accept="image/*"
                                @change="handleFileUpload"
                                />
                                <div v-if="contactForm.files.length > 0" class="mt-2">
                                    <div v-for="(file, index) in contactForm.files" :key="index" class="d-flex align-items-center mb-2">
                                        <span class="me-2" style="color: white;">{{ file.name }}</span>
                                        <button 
                                            type="button" 
                                            class="btn btn-danger btn-sm" 
                                            @click="removeFile(index)"
                                            style="padding: 0.25rem 0.5rem; min-width: 70px;"
                                        >
                                            Ta bort
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <!-- Submit button -->
                            <div class="text-center">
                                <button 
                                    class="btn btn-primary" 
                                    id="submitButton" 
                                    type="submit">
                                    Skicka
                                </button>
                            </div>

                            <!-- Submit success message -->
                            <div class="d-none" id="submitSuccessMessage">
                                <div class="text-center mb-3 mt-2 text-white">
                                    <div class="fw-bolder">Form submission successful!</div>
                                    Your message has been sent.
                                </div>
                            </div>

                            <!-- Submit error message -->
                            <div v-if="errorMessage">
                                <p>Något gick fel, försök igen senare.</p>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </section>
</template>

<script>

export default {
    name: "SendMessageSection",
    data() {
        return {
            contactForm: {
                email: '',
                name: '',
                reg: '',
                message: '',
                files: [],
            },
            errorMessage: '',
        };
    },
    methods: {
        handleFileUpload(event) {
            const selectedFiles = Array.from(event.target.files);
            const existingFileNames = this.contactForm.files.map(file => file.name);

            selectedFiles.forEach(file => {
                if (!existingFileNames.includes(file.name)) {
                    this.contactForm.files.push(file); // Only add the file if it's not already in the list
                }
            });
        },
        removeFile(index) {
            this.contactForm.files.splice(index, 1);
        },
        async contact() {
            try {
                const formData = new FormData();
                formData.append('name', this.contactForm.name);
                formData.append('email', this.contactForm.email);
                formData.append('reg', this.contactForm.reg);
                formData.append('message', this.contactForm.message);

                // Append files
                this.contactForm.files.forEach(file => {
                    formData.append('files[]', file);
                });

                const response = await fetch('/send_email.php', {
                    method: 'POST',
                    body: formData,
                });

                const result = await response.json();
                
                if (result.status === 'success') {
                    alert(result.message);
                    this.resetForm();
                    this.errorMessage = '';
                } else {
                    this.errorMessage = result.message || 'Ett fel uppstod vid sändning av meddelandet.';
                }
            } catch (error) {
                this.errorMessage = 'Ett tekniskt fel uppstod. Försök igen senare.';
                console.error('Form submission error:', error);
            }
        },
        resetForm() {
            this.contactForm = {
                email: '',
                name: '',
                reg: '',
                message: '',
                files: []
            };
        },        
    },
};
</script>
