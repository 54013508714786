<template>
  <div id="app">
      <NavBar />
      <Masthead />
      <AboutSection />
      <ReparationsSection />
      <SendMessageSection />
      <ContactSection />
      <AppFooter />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Masthead from "./components/Masthead.vue";
import AboutSection from "./components/AboutSection.vue";
import ReparationsSection from "./components/ReparationsSection.vue";
import SendMessageSection from "./components/SendMessageSection.vue";
import ContactSection from "./components/ContactSection.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
    components: {
        NavBar,
        Masthead,
        AboutSection,
        ReparationsSection,
        SendMessageSection,
        ContactSection,
        AppFooter,
    },
};
</script>

<style>

</style>
