<template>
    <section class="projects-section bg-light" id="projects">
        <div class="container px-4 px-lg-5">
            <!-- Featured Project Row -->
            <div class="row gx-0 mb-4 mb-lg-5 align-items-center d-flex">
                <div class="col-xl-8 col-lg-7">
                    <img class="img-fluid h-100" src="assets/img/slideshow5new.jpg" alt="..." style="object-fit: cover;" />
                </div>
                <div class="col-xl-4 col-lg-5">
                    <div class="featured-text text-center text-lg-left h-100 d-flex align-items-center">
                        <div>
                            <h4>Ett Högkvalitativt Bilskadecenter</h4>
                            <p class="text-black-50 mb-0">Med över 40 års erfarenhet driver jag mitt eget bilskadecenter. Jag hanterar alla typer av bilskador och använder modern teknologi för att snabbt och professionellt återställa din bil.</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Project One Row -->
            <div class="row gx-0 mb-5 mb-lg-0 justify-content-center d-flex">
                <div class="col-lg-6">
                    <img class="img-fluid h-100" src="assets/img/joakim.jpg" alt="..." style="object-fit: cover;" />
                </div>
                <div class="col-lg-6">
                    <div class="bg-black text-center h-100 project d-flex align-items-center">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <h4 class="text-white">Joakim</h4>
                            <p class="mb-0 text-white-50">
                                Med över 18 års erfarenhet inom arbete med Mercedes och Nissan, samt lika lång erfarenhet av Toyota, har jag specialiserat mig på omfattande reparationer och underhåll av fordon. <br><br>
                                Jag har gedigen kunskap om personbilar och hanterar alla typer av reparationsarbeten med hög precision och professionalism. <br><br>
                                Med flera års erfarenhet inom fordonsbranschen har jag utvecklat en djup förståelse för fordonsteknik och kundanpassade lösningar.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Project Two Row -->
            <div class="row gx-0 justify-content-center d-flex">
                <div class="col-lg-6">
                    <img class="img-fluid h-100" src="assets/img/joakim.jpg" alt="..." style="object-fit: cover;" />
                </div>
                <div class="col-lg-6 order-lg-first">
                    <div class="bg-black text-center h-100 project d-flex align-items-center">
                        <div class="project-text w-100 my-auto text-center text-lg-right">
                            <h4 class="text-white">Reparation1</h4>
                            <p class="mb-0 text-white-50">
                                Med över 18 års erfarenhet inom arbete med Mercedes och Nissan, samt lika lång erfarenhet av Toyota, har jag specialiserat mig på omfattande reparationer och underhåll av fordon. <br><br>
                                Jag har gedigen kunskap om personbilar och hanterar alla typer av reparationsarbeten med hög precision och professionalism. <br><br>
                                Med flera års erfarenhet inom fordonsbranschen har jag utvecklat en djup förståelse för fordonsteknik och kundanpassade lösningar.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: "ReparationsSection",
};
</script>