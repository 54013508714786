<template>
    <section class="about-section text-center" id="about">
            <div class="container px-4 px-lg-5">
                <div class="row gx-4 gx-lg-5 justify-content-center">
                    <div class="col-lg-8">
                        <h2 class="text-white mb-4">Drop-in skadebesiktning</h2>
                        <p class="text-white-50">
                            Välkommen in på drop-in skadebesiktning. <br>
                            Vardagar: 09:00 - 16:00 <br>
                            Lunch: 13:00 - 14:00 <br>
                            Verkstadsgatan 3, Alingsås
                        </p>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
    name: "AboutSection",
};
</script>