<template>
    <header class="masthead">
        <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
            <div class="d-flex justify-content-center">
                <div class="text-center">
                    <h1 class="mx-auto my-0 text-uppercase">JA-Bilskador</h1>
                    <h2 class="text-white-50 mx-auto mt-2 mb-5">
                        Har du en skada på din bil? Kontakta oss så hjälper vi dig!
                    </h2>
                    <a class="btn btn-primary" href="#signup">Kontakt</a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "AppMasthead",
};
</script>
